<template>
  <div :class="$style.home">
    <div :class="$style.left">
      <img :class="$style.icon" :src="data.icon" />
      <BjButton v-if="!data.is_open" type="primary" :class="$style.add" @click="onAdd()">
        <i class="left ri-bank-card-line" />
        订购应用
      </BjButton>

      <BjButton v-if="data.is_open" type="primary" :class="$style.renew" @click="onAdd()">
        <i class="left ri-bank-card-line" />
        续费费用
      </BjButton>
      <BjButton v-if="data.is_open" :class="$style.set" @click="toDetail()">
        <i class="left ri-settings-line" />
        功能管理
      </BjButton>
      <div :class="$style.text">
        <p>所属分类：{{ data.category }}</p>
        <p>更新时间：{{ data.updated_time }}</p>
        <p>支持语言：{{ data.language }}</p>
        <div :class="$style.line" />
        <p>适用版本：{{ data.support_version }}</p>
        <p>版本包含：{{ data.contain_version }}</p>
      </div>
    </div>
    <div :class="$style.right">
      <div :class="$style.name">
        {{ data.name }}
        <span :class="$style.price">{{ data.price }}</span>
      </div>
      <div :class="$style.provider">由 {{ data.provider }} 提供</div>
      <div :class="$style.summary">{{ data.summary }}</div>
      <div :class="$style.title">应用截图</div>
      <a-carousel autoplay>
        <div v-for="item in data.app_image" :key="item" :class="$style.banner">
          <img :src="item" />
        </div>
      </a-carousel>
      <div :class="$style.title">
        应用版本
        <span :class="$style.more">全部记录</span>
      </div>
      <div v-if="data.version" :class="$style.version">
        <div :class="$style.versionName">{{ data.version[0].version }}</div>
        <div :class="$style.versionDesc" v-html="data.version[0].description" />
      </div>
      <div>
        <div :class="$style.title">应用介绍</div>
        <div v-html="data.description" />
      </div>
    </div>

    <bj-modal title="订购应用" :visible="visible" :width="800" :body-style="{ height: '400px' }">
      <div :class="$style.modal">
        <div :class="$style.tip">
          应用服务的订购时长最长可以与版本时长保持同步，最小可以选择按周或按月订购，应用服务到期后，前台将禁止用户访问，后台将不能添加新的数据。
        </div>
        <div :class="$style.box">
          <div :class="$style.boxLeft">
            <span class="required">*</span>
            订购时长
          </div>
          <div :class="$style.boxRight" class="pr-20">
            <a-slider
              v-model="infoNum"
              :tooltip-visible="false"
              :marks="marks"
              :min="1"
              :max="9"
              @afterChange="onAfterChange"
            />
          </div>
        </div>
      </div>
      <template #footer>
        <div :class="$style.footer">
          <div :class="$style.price">订购费用：￥{{ info.price }}</div>
          <div :class="$style.btns">
            <BjButton type="primary" :disabled="!info.is_valid" :tooltips="info.message" @click="handleOk"
              >添加到购物车</BjButton
            >
            <BjButton :class="$style.cannel" @click="handleCancel">取消</BjButton>
          </div>
        </div>
      </template>
    </bj-modal>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'

import { centerStoreService } from '@/service'

const service = new centerStoreService()

export default {
  name: 'Home',
  data() {
    return {
      data: {},
      visible: false,
      info: {
        is_valid: true,
        message: '',
        price: '0',
      },
      infoNum: 1, // 选的条条数据
      marks: {
        1: '1周',
        2: '1个月',
        3: '2个月',
        4: '3个月',
        5: '半年',
        6: '1年',
        7: '2年',
        8: '3年',
        9: '与版本同步',
      },
      type: {
        1: {
          time_unit: 1,
          time_num: 1,
        },
        2: {
          time_unit: 2,
          time_num: 1,
        },
        3: {
          time_unit: 2,
          time_num: 2,
        },
        4: {
          time_unit: 2,
          time_num: 3,
        },
        5: {
          time_unit: 2,
          time_num: 6,
        },
        6: {
          time_unit: 3,
          time_num: 1,
        },
        7: {
          time_unit: 3,
          time_num: 2,
        },
        8: {
          time_unit: 3,
          time_num: 3,
        },
        9: {
          time_unit: 4,
          time_num: 1,
        },
      },
    }
  },
  created() {
    this.getInfo()
  },
  methods: {
    ...mapMutations(['REFRESHSHOPCAR']),
    async getInfo() {
      try {
        const { data } = await service.getDetail({
          id: Number(this.$route.query.id),
        })
        this.data = data
      } catch (e) {}
    },
    toDetail() {
      this.$router.push({
        name: '',
      })
    },
    onAdd() {
      this.visible = true
      this.infoNum = 1
      this.onAfterChange()
    },
    async handleOk() {
      try {
        let time_unit = this.type[this.infoNum].time_unit
        let time_num = this.type[this.infoNum].time_num
        await service.pushCar({
          foreign_type: 2, // 商品类型 1-版本 2-应用 3-服务
          foreign_key_id: Number(this.$route.query.id),
          time_unit: time_unit,
          time_num: time_num,
        })
        this.REFRESHSHOPCAR(Math.random())
        this.visible = false
        this.$message.success('添加成功')
      } catch (e) {}
    },
    handleCancel() {
      this.visible = false
    },
    async onAfterChange() {
      try {
        if (this.data.sale_type === 1) {
          let time_unit = this.type[this.infoNum].time_unit
          let time_num = this.type[this.infoNum].time_num
          const { data } = await service.getPrice({
            foreign_type: 2,
            foreign_key_id: Number(this.$route.query.id),
            time_unit: time_unit,
            time_num: time_num,
          })
          this.info = data
        }
      } catch (e) {}
    },
  },
}
</script>

<style lang="less" module>
.home {
  display: flex;

  .left {
    width: 150px;
    margin-right: 30px;

    .icon {
      width: 150px;
      height: 150px;
      border-radius: 6px;
    }

    .add {
      width: 100%;
      margin: 30px 0;
    }

    .renew {
      width: 100%;
      margin-top: 30px;
      margin-bottom: 10px;
    }

    .set {
      width: 100%;
      margin-bottom: 30px;
    }

    .text {
      font-size: 12px;
      color: #5c5c5c;

      .line {
        width: 100%;
        border-top: 1px solid #eee;
        margin-bottom: 10px;
      }

      p {
        margin-bottom: 10px;
      }
    }
  }

  .right {
    flex: 1;

    .banner {
      width: 100%;
      aspect-ratio: 1 / 0.52;
      margin-bottom: 20px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .name {
      font-size: 24px;
      color: #000;
      line-height: 36px;

      .price {
        float: right;
        font-size: 14px;
        color: @error-color;
      }
    }

    .provider {
      font-size: 12px;
      color: #5c5c5c;
      margin: 10px 0 20px 0;
    }

    .summary {
      font-size: 14px;
      color: #000;
      margin-bottom: 20px;
    }

    .title {
      font-size: 16px;
      color: #000;
      margin-bottom: 14px;

      .more {
        float: right;
        color: @primary-color;
        font-size: 14px;
      }
    }

    .version {
      margin-bottom: 20px;

      &-name {
        font-size: 14px;
        color: #000;
      }

      &-desc {
        font-size: 12px;
        color: #5c5c5c;
      }
    }
  }
}

.modal {
  .tip {
    width: 100%;
    padding: 10px;
    color: #5c5c5c;
    background: #fafafa;
    font-size: 12px;
  }

  .box {
    width: 100%;
    display: flex;

    &-left {
      width: 90px;
      line-height: 54px;
    }

    &-right {
      flex: 1;
      padding-right: 30px;
    }
  }
}

.footer {
  display: flex;
  align-items: center;

  .price {
    width: 50%;
    text-align: left;
    font-size: 12px;
  }

  .btns {
    flex: 1;
    text-align: right;

    .cannel {
      margin-left: 10px !important;
    }
  }
}
</style>
